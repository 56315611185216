@import "~@/styles/utils";
@import "~@/styles/layout";
@import "~@/styles/spacing";
@import "~@/styles/typography";
@import "~@/styles/breakpoints";
@import "~@/styles/colors";

.articlePortableText {
  padding-bottom: spacer(80);
  @include respond-to("small") {
    // padding-bottom: spacer(160);
    padding-bottom: rem-calc(128);
  }

  ul {
    @include outer-spacing;
    @include grid;
    @include grid-container;

    list-style-type: disc;
    list-style-position: outside;

    li {
      margin-left: 1.5rem;
      grid-column: 1 / -1;
      @include type_body;
      margin-bottom: spacer(12);
      @include respond-to("small") {
        grid-column: 7 / -1;
      }
      @include respond-to('xlarge') {
        grid-column: 7 / 12;
      }
      @include respond-to("xxxlarge") {
        grid-column: 7 / -1;
      }
    }
  }

  ol {
    grid-column: 1 / -1;
    @include outer-spacing;
    @include grid;
    @include grid-container;
    margin-bottom: spacer(56);

    li {
      grid-column: 1 / -1;
      @include respond-to("small") {
        grid-column: 8 / -1;
        margin-left: rem-calc(-24);
      }
      @include respond-to('xlarge') {
        grid-column: 8 / 12;
      }
      @include respond-to("xxxlarge") {
        grid-column: 8 / -1;
      }

      @include grid;
      @include type_body;
      margin-bottom: spacer(24);

      & > :first-child {
        grid-column: 1 / 1;
      }

      & > :nth-child(2) {
        grid-column: 2 / -1;
      }
    }
  }
}

.gridContainer {
  @include grid-container;
  @include outer-spacing;
  @include grid;

  h1,
  h2 {
    grid-column: 1 / 7;
  }

  blockquote {
    grid-column: 1 / 11;
    @include respond-to("xlarge") {
      grid-column: 1 / 9;
    }
    @include respond-to("xxxlarge") {
      grid-column: 1 / 7;
    }
  }

  p,
  //h2,
  h3,
  h4,
  h5,
  h6 {
    grid-column: 1 / -1;
    @include respond-to("small") {
      grid-column: 7 / -1;
    }
    @include respond-to('xlarge') {
      grid-column: 7 / 12;
    }
    @include respond-to("xxxlarge") {
      grid-column: 7 / -1;
    }
  }

  h1,
  h2 {
    margin-bottom: spacer(48);
    @include type_heading_1;
  }

  /*
  h2 {
    @include type_heading_2;
    margin-bottom: spacer(24);
    @include respond-to("small") {
      margin-bottom: spacer(48);
    }
  }
  */

  h3 {
    @include type_heading_3;
    margin-bottom: spacer(16);
    @include respond-to("small") {
      margin-bottom: spacer(32);
    }
  }

  h4 {
    @include type_heading_4;
    margin-bottom: spacer(12);
    @include respond-to("small") {
      margin-bottom: spacer(24);
    }
  }

  p {
    @include type_body;
    margin-bottom: spacer(24);
  }

  .bodySmall {
    @include type_body_small;
  }

  .bodyLarge {
    @include type_body_large;
  }

  blockquote {
    padding: spacer(48) 0 spacer(48) 0;
    margin-bottom: spacer(24);
    @include type_heading_2_m;
    @include respond-to("small") {
      padding: spacer(80) 0 spacer(80) 0;
    }
  }
}

.paragraph + .heading1 {
  padding-top: spacer(80);
  @include respond-to("small") {
    padding-top: spacer(160);
  }
}

.paragraph + .textCarouselRenderer,
.paragraph + ol,
.paragraph + ul {
  padding-top: spacer(24);
}

.imageGalleryRenderer {
  margin-top: spacer(24);
  @include respond-to("small") {
    margin-top: spacer(48);
  }
}

.textGridRenderer {
  @include grid-container;
  @include outer-spacing;
  @include grid;
  row-gap: spacer(24);
  @include respond-to("small") {
    row-gap: spacer(48);
  }

  .textBlock {
    grid-column: 1 / 7;
    &:nth-of-type(even) {
      grid-column: 2 / -1;
      margin-bottom: spacer(24);
      @include respond-to("small") {
        grid-column: 7 / -1;
        margin-bottom: 0;
      }
    }
  }
}

.textCarouselRenderer {
  grid-column: 1 / -1;
}

.light {
  background: $background-white;
  color: $text-black;
}
