@import "~@/styles/colors";
@import "~@/styles/spacing";
@import "~@/styles/colors";
@import "~@/styles/layout";
@import "~@/styles/utils";
@import "~@/styles/typography";
@import "~@/styles/breakpoints";

.officesBanner {
  color: $text-white;
  background: $tints-kyber-800;
  min-height: rem-calc(516);
}

.container {
  @include grid-container;

  padding: spacer(64) 0 spacer(32) 0;
  @include respond-to("small") {
    padding: spacer(80) 0 spacer(72) 0;
  }
}

.content {
  @include outer-spacing;
  @include grid;
}

.label {
  @include separator-line;
  @include outer-spacing;
  grid-row: 1;
  grid-column: 1 / -1;
  margin-bottom: spacer(64);
  @include respond-to("small") {
    margin-bottom: rem-calc(90);
  }

  p {
    @include type_caption_title_small;
  }
}

.heading {
  margin-bottom: rem-calc(48);
  grid-row: 2;
  grid-column: 1 / -1;
  @include respond-to("small") {
    grid-row: 1;
    grid-column: 1 / 7;
  }

  h2 {
    @include type_heading_2_m;
    @include respond-to("small") {
      @include type_heading_1;
    }
  }
}

.officeList {
  grid-row: 3;
  grid-column: 1 / -1;
  @include respond-to("small") {
    grid-row: 2;
    grid-column: 7 / 12;
  }
}
