@import "~@/styles/colors";
@import "~@/styles/spacing";
@import "~@/styles/layout";
@import "~@/styles/utils";
@import "~@/styles/breakpoints";

.circleContainer {
  display: block;
}

.circle {
  display: block;
  border: 1.5px solid $stroke-black;
  border-radius: rem-calc(100px);
  width: 1.5em;
  height: 1.5em;
  text-align: center;
}
