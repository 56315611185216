@import "~@/styles/spacing";

.button {
  color: currentColor;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: none;

  padding: 0;
  cursor: pointer;

  opacity: 1;
  transition: opacity cubic-bezier(0.25, 0, 0, 1) 640ms;
  will-change: opacity;

  &:disabled {
    opacity: 0.3;
  }

  &:focus {
    // outline: none;
  }

  & + .button {
    margin-left: spacer(56);
  }

  svg {
    path {
      fill: currentColor;
    }
  }

  &.dark {
    svg {
      path {
        fill: #fff;
      }
    }
  }
}
