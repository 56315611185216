@import "~@/styles/typography";
@import "~@/styles/layout";
@import "~@/styles/spacing";
@import "~@/styles/colors";
@import "~@/styles/breakpoints";

.container {
  @include grid-container;
  @include outer-spacing;
  @include respond-to("small") {
    @include grid;
  }
}

.descriptionList {
  padding-bottom: spacer(80);
  border-bottom: 1px solid $tints-obsidian-600;
  margin-bottom: spacer(32);
  @include respond-to("small") {
    grid-column: 7 / -1;
    margin-bottom: rem-calc(36);
  }

  dt {
    @include type_caption_title;
    margin-bottom: spacer(8);
    color: $text-muted;
  }

  dd {
    @include type_body;
    margin-bottom: spacer(24);
  }
}

.content {
  display: flex;
}

.column {
  width: 50%;
}
