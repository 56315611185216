@import "~@/styles/colors";
@import "~@/styles/spacing";
@import "~@/styles/layout";
@import "~@/styles/utils";
@import "~@/styles/breakpoints";

.textCarousel {
  margin-bottom: spacer(80);
  cursor: pointer;

  :global {
    .swiper-wrapper {
      transition-timing-function: cubic-bezier(0.25, 0, 0, 1);
      @include outer-spacing;
      @include respond-to("small") {
        margin-left: calc(50vw - 34px);
      }
    }

    .swiper-slide {
      width: rem-calc(334px);
      display: flex;
      &:last-of-type {
        margin-right: calc(100vw - 334px);
      }
    }
  }
}

.content {
  display: flex;
  overflow: scroll;
  margin-bottom: spacer(24);
  padding-right: spacer(24);
  padding-left: spacer(24);
  @include respond-to("small") {
    padding-left: calc(1.5rem + 50vw);
  }
}

.buttonGroup {
  @include outer-spacing;
}

.after {
  flex: 0 0 spacer(24);
  @include respond-to("small") {
    flex: 0 0 calc(50vw - 310px);
  }
}
