@import "~@/styles/spacing";
@import "~@/styles/utils";
@import "~@/styles/breakpoints";

.textCarouselSlide {
  margin-right: spacer(24);
}

.numberTag {
  margin-bottom: spacer(16);
}
