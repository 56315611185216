@import "~@/styles/utils";
@import "~@/styles/layout";
@import "~@/styles/typography";
@import "~@/styles/colors";
@import "~@/styles/spacing";

.button {
  @include type_body;
  background: $background-white-elevation;
  color: $text-black;

  border-radius: 2px;

  width: 100%;
  @include respond-to("small") {
    width: rem-calc(327);
  }
  @include respond-to("xxlarge") {
    width: rem-calc(400);
  }

  /**
  * Makes the child element fill the container,
  * and remove default styles.
  */
  display: inline-block;
  * {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: rem-calc(20px) rem-calc(24px);
    background: transparent;
    color: currentColor;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    text-align: center;
  }
}

.primaryButton:hover {
  background: $background-white;
}

//
// THEMES
//
.kyber {
  transition: all cubic-bezier(0.25, 0, 0, 1) 320ms;
  will-change: all;
  background: $brand-kyber-green;
  color: $text-black;
  &:hover {
    background: $tints-kyber-300;
  }
}

.inactive,
.inactive:hover {
  background: $tints-obsidian-600;
  color: $text-white;
}

.oxford {
  transition: all cubic-bezier(0.25, 0, 0, 1) 320ms;
  will-change: all;
  background: $tints-oxford-800;
  color: $text-white;
  &:hover {
    background: $tints-oxford-600;
  }
}

.transparent {
  background-color: transparent;
  color: $text-white;
  border-radius: 2px;
  border: 1px solid currentColor;
}
