@import "~@/styles/utils";
@import "~@/styles/typography";
@import "~@/styles/spacing";

.office {
  display: flex;
  flex-direction: column;
  margin-bottom: rem-calc(48);
}

.tCity {
  @include type_caption_title;
  margin-bottom: rem-calc(16px);
}

.contactInfo {
  @include type_body_small;
  @include respond-to("small") {
    @include type_body_large;
  }

  display: flex;
  flex-direction: column;
}

.mapsLink {
  font-size: rem-calc(16);
  line-height: rem-calc(24);
  margin-top: spacer(8);
  @include respond-to("small") {
    margin-top: spacer(16);
  }
}
