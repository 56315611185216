@import "~@/styles/utils";
@import "~@/styles/layout";
@import "~@/styles/spacing";
@import "~@/styles/typography";
@import "~@/styles/colors";

.ctaLink {
  min-height: rem-calc(64);
  margin-top: spacer(40);
  @include respond-to("small") {
    margin-top: spacer(16);
  }

  display: flex;
  justify-content: space-around;
  padding: spacer(12) spacer(32) spacer(12) spacer(32);
  border-radius: 2px;
  @include type_body;
  text-decoration: none;

  color: $text-white;
  background-color: $tints-oxford-800;

  width: 100%;
  @include respond-to("small") {
    width: rem-calc(327);
  }
  @include respond-to("xxlarge") {
    width: rem-calc(400);
  }

  transition: background-color cubic-bezier(0.25, 0, 0, 1) 320ms;
  will-change: background-color;
}

.spacer {
  margin-left: spacer(12);
}

.arrow {
  transform: rotate(-45deg);
}

.buttonContent {
  display: flex;
  align-items: center;
}

.primary {
  background-color: transparent;
  color: currentColor;

  border: 1px solid currentColor;

  &:hover {
    background-color: $background-white;
    color: $text-black;
  }

  &.light {
    &:hover {
      background-color: $background-black;
      color: $text-white;
    }
  }

  svg {
    path {
      fill: currentColor;
    }
  }
}

.secondary {
  color: $text-white;
  background-color: $tints-oxford-800;
  &:hover {
    background-color: $tints-oxford-600;
  }
}
