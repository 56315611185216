@import "~@/styles/layout";
@import "~@/styles/spacing";
@import "~@/styles/typography";
@import "~@/styles/breakpoints";

.questionAnswers {
  @include grid-container;
  @include outer-spacing;
  margin-bottom: spacer(64);
  @include respond-to("small") {
    margin-bottom: spacer(160);
  }

  /**
  * If we receive several paragraph elements,
  * apply spacing between them according to design.
  */
  p:not(:last-child) {
    margin-bottom: spacer(16);
  }
}

.listItem {
  @include grid;
}

.question,
.answer {
  margin-bottom: spacer(32);
}

.question {
  grid-column: 1 / 4;
  @include respond-to("small") {
    grid-column: 7 / 9;
  }
}

.answer {
  grid-column: 2 / -1;
  @include respond-to("small") {
    grid-column: 9 / -1;
  }
}
