@import "~@/styles/colors";
@import "~@/styles/layout";
@import "~@/styles/spacing";
@import "~@/styles/typography";

.ctaBanner {
  color: $text-white;
  background: $tints-kyber-800;
}

.oxfordTheme {
  background: $tints-oxford-800;
}

.container {
  @include grid-container;
  @include outer-spacing;

  padding-top: spacer(64);
  padding-bottom: spacer(80);
  @include respond-to("small") {
    padding-top: spacer(80);
    padding-bottom: spacer(120);
  }
}

.wrapper {
  border-top: 1px solid $stroke-white;
}

.label {
  @include type_caption_title_small;
  display: block;
  margin-top: spacer(8);
  margin-bottom: spacer(64);
  @include respond-to("small") {
    margin-bottom: rem-calc(90px);
  }
}

.content {
  @include grid;
}

.heading {
  // @include type_heading_2_m;
  grid-row: 1;
  grid-column: 1 / -1;
  margin-bottom: rem-calc(40px);
  @include type_heading_1;
  @include respond-to("small") {
    grid-column: 1 / 7;
    margin-bottom: spacer(48);
  }
}

.lead {
  p {
    @include type_body_small;
  }
  margin-bottom: spacer(64);
  grid-row: 2;
  grid-column: 1 / -1;
  @include respond-to("small") {
    p {
      @include type_body_large;
    }
    grid-column: 7 / -1;
  }

  p:not(:last-of-type) {
    margin-bottom: spacer(16);
  }
}

.button {
  grid-row: 3;
  grid-column: 1 / -1;
  @include respond-to("small") {
    grid-column: 7 / -1;
  }
}


.oxfordButton {
  background-color: $tints-oxford-800;
  color: $text-white;
  border-radius: 2px;
  border: 1px solid currentColor;
  &:hover {
    background-color: $tints-oxford-600;
  }
}
